input[type='checkbox'].filled-in {
  // common rules
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
    opacity: 0;
  }
  & + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
    -webkit-user-select: none;
    margin-bottom: var(--padding-medium);
    &::before,
    &::after {
      content: '';
      position: absolute;
      transition:
        border 0.25s,
        background-color 0.25s,
        width 0.2s 0.1s,
        height 0.2s 0.1s,
        top 0.2s 0.1s,
        left 0.2s 0.1s;
      z-index: 1;
    }
    &::before {
      top: 0;
      left: 1px;
      width: 10px;
      height: 18px;
      -webkit-transform: rotate(37deg);
      transform: rotate(37deg);
      -webkit-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
      border-style: solid;
      border-width: 2px;
      opacity: 0;
    }
    &::after {
      border-radius: 4px;
      left: 0;
      top: 0;
      box-sizing: border-box;
      width: 24px;
      height: 24px;
      border: 1px solid var(--color-light);
      z-index: 0;
    }
  }
  &:checked {
    & + label {
      &::before {
        opacity: 1;
      }
    }
  }

  // color rules: dark style
  &.nn-dark {
    & + label {
      &::before {
        border-color: transparent var(--color-white) var(--color-white) transparent;
      }

      &::after {
        background-color: var(--color-white);
      }

      a {
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &:checked {
      & + label {
        &::after {
          border-color: var(--color-white);
          background-color: var(--color-link-btn);
        }
      }
    }
  }

  // color rules: light style
  &.nn-light {
    &:checked {
      & + label {
        &::before {
          border-color: transparent var(--color-link-btn) var(--color-link-btn) transparent;
        }
        &::after {
          border-color: var(--color-link-btn);
          background-color: var(--color-white);
        }
      }
    }
    & + label {
      color: var(--color-white);
      &::after {
        background-color: var(--color-white);
      }
      a {
        font-weight: 500;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  // color rules: disabled style
  &:disabled {
    & + label {
      cursor: not-allowed;
      &::before {
        border-color: transparent var(--color-white) var(--color-white) transparent;
      }
      &::after {
        background-color: var(--color-light);
      }
      a {
        font-weight: 500;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &:checked {
      & + label {
        &::after {
          border-color: var(--color-white);
          background-color: var(--color-light);
        }
      }
    }
  }
}
