@import '../base/variables';
@import '../base/mobile';
@import './variables';

.#{$opxp_edison_theme} .section.hero-section {
  padding-bottom: 0;
  overflow: hidden;
  .hero-section-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: var(--padding-large);
    @include mq {
      padding-bottom: $padding-content-height-mobile;
    }
  }
  .hero-section-illustration {
    display: flex;
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
    align-content: flex-end;
    --max-asset-size: 445px;
    --min-asset-size: 345px;
    --height: var(--max-asset-size); //default value to fallback if not set by script

    .illustration-wrapper {
      border-radius: 50%;
      background-color: var(--color-white);
      --circle-height: max(var(--min-asset-size), min(var(--height), var(--max-asset-size)));
      margin-bottom: calc(-0.3 * var(--circle-height));
      max-height: var(--max-asset-size);
      max-width: var(--max-asset-size);
      min-height: var(--min-asset-size);
      min-width: var(--min-asset-size);
      @include mq {
        margin-bottom: -$section-asset-padding-mobile;
        height: $section-asset-size-mobile;
        width: $section-asset-size-mobile;
      }
      .hero-section-image,
      .lottie-container {
        width: var(--circle-height);
        height: var(--circle-height);
        overflow: hidden;
        padding: calc(0.18 * var(--circle-height));
        padding-bottom: calc(0.3 * var(--circle-height));
        @include mq {
          width: 100%;
          height: 100%;
          padding-top: 84px;
          padding-left: 93px;
          padding-right: 93px;
          padding-bottom: 106px;
        }
      }
    }
  }
}
