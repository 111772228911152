@import '../styles';

.theme-edison {
  .App-footer {
    .footer-section {
      display: flex;
      flex-direction: column;
      padding-bottom: var(--padding-large);
    }

    .footer-icons {
      order: 1;
    }

    .footer-language {
      display: flex;
      justify-content: flex-end;
      order: 2;
    }

    .footer-icons-bar {
      justify-content: flex-start;
      margin-bottom: var(--padding-large);
      .nav-item {
        margin-right: var(--padding-large);
      }
    }

    .footer-mobile {
      padding-bottom: var(--padding-large);
      h3 {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: var(--padding-small);
      }
      a {
        margin-right: var(--padding-small);
      }
    }

    .footer-nav {
      width: 100% !important;
      margin-top: var(--padding-large);
      order: 3;
      padding: 0;

      .nav-item {
        padding: 0 20px !important;
      }
      .nav-extras {
        margin-top: 16px;
      }
    }

    .footer-copyright {
      padding-top: var(--padding-large);
      .copyright {
        margin-top: var(--padding-large);
        order: 1;
      }
      .legals {
        margin-top: var(--padding-large);
        order: 2;
        display: flex;
        justify-content: flex-end;
        .legals-nav a {
          color: var(--color-body-text);
          font-size: 14px;
          line-height: 16px;
        }
      }
    }

    .legals {
      .legals-nav {
        display: flex;
        justify-content: flex-end;
        align-content: flex-start;
        .legals-link {
          padding: 0 0 var(--padding-small) var(--padding-large);
          &:first-child {
            padding-left: 0;
          }
          a {
            color: var(--color-body-text);
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
          }
        }
      }
    }

    @include mq {
      margin-bottom: var(--padding-large);

      .footer-icons {
        order: 1;
      }

      .footer-language {
        justify-content: flex-start;
        margin-top: var(--padding-large);
        order: 3;
      }

      .footer-nav {
        margin-top: var(--padding-large);
        order: 2;
        width: 100%;
        .nav {
          justify-content: space-between;
          .nav-item {
            padding: 0;
            margin: var(--padding-tiny) 0;
          }
        }
        .nav-extras {
          margin-top: 0;
        }
      }

      .footer-copyright {
        padding-top: 0;
        .copyright {
          margin-top: var(--padding-medium);
          order: 2;
        }
        .legals {
          margin-top: var(--padding-medium);
          order: 1;
          .legals-nav {
            width: 100%;
            justify-content: space-between;
            .legals-link {
              padding: 0;
              margin: var(--padding-tiny) 0;
            }
          }
        }
      }
    }
  }

  iframe {
    border: none;
  }
}

.legals-portal {
  .legals-dialog {
    width: 900px;
    background-color: #fff;
    .legals-dialog-body {
      margin: 0;
    }
    .legals-frame {
      width: 100%;
    }
  }
}
