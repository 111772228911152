@import '../base/variables';
@import '../base/colors';
@import '../base/typography';

@mixin placeholder-styles {
  &::placeholder {
    @content;
  }
}

.string-input-container {
  input.string-input {
    @extend %body-text;
    width: 100%;
    border: 0;
    padding: 0 2px;
    outline: none;
    background-color: transparent;
    margin-bottom: 4px;
    border-top: 1px solid transparent;
    //add separate selector to support Edge and some other older browsers
    @include placeholder-styles {
      opacity: 1;
      color: $color-white;
    }
  }

  .error-feedback {
    @extend %error;
    opacity: 0;
    text-align: right;
    margin-bottom: 4px;
    // prevent container from collapsing when empty
    height: $padding-small;
  }

  &.is-invalid {
    input.string-input {
      padding-right: 24px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTIiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0xOC43MDkxIDE1LjA5NTFMMTMuNzYzNiA2LjAwMzkzQzEyLjk2OSA0LjY2NjI1IDExLjAzMjEgNC42NjQ0NyAxMC4yMzY0IDYuMDAzOTNMNS4yOTExMyAxNS4wOTUxQzQuNDc4ODUgMTYuNDYyIDUuNDYyMjcgMTguMTkyOSA3LjA1NDMxIDE4LjE5MjlIMTYuOTQ1NUMxOC41MzYyIDE4LjE5MjkgMTkuNTIxNCAxNi40NjM0IDE4LjcwOTEgMTUuMDk1MVpNMTIgMTYuNTUyMkMxMS41NDc4IDE2LjU1MjIgMTEuMTc5NyAxNi4xODQxIDExLjE3OTcgMTUuNzMxOUMxMS4xNzk3IDE1LjI3OTcgMTEuNTQ3OCAxNC45MTE2IDEyIDE0LjkxMTZDMTIuNDUyMiAxNC45MTE2IDEyLjgyMDMgMTUuMjc5NyAxMi44MjAzIDE1LjczMTlDMTIuODIwMyAxNi4xODQxIDEyLjQ1MjIgMTYuNTUyMiAxMiAxNi41NTIyWk0xMi44MjAzIDEzLjI3MUMxMi44MjAzIDEzLjcyMzIgMTIuNDUyMiAxNC4wOTEzIDEyIDE0LjA5MTNDMTEuNTQ3OCAxNC4wOTEzIDExLjE3OTcgMTMuNzIzMiAxMS4xNzk3IDEzLjI3MVY5LjE2OTQzQzExLjE3OTcgOC43MTcyMiAxMS41NDc4IDguMzQ5MTIgMTIgOC4zNDkxMkMxMi40NTIyIDguMzQ5MTIgMTIuODIwMyA4LjcxNzIyIDEyLjgyMDMgOS4xNjk0M1YxMy4yNzFaIiBmaWxsPSIjRUI1NzU3Ii8+Cjwvc3ZnPg==');
      background-position: right;
      background-repeat: no-repeat;
    }
    .error-feedback {
      opacity: 1;
    }
  }

  &.light {
    input.string-input {
      border-bottom: 1px solid $color-white;
      color: $color-white;

      @include placeholder-styles {
        color: $color-white;
      }
    }
    .error-feedback {
      color: $color-white;
    }
  }

  &.dark {
    input.string-input {
      border-bottom: 1px solid $color-light;
      color: $color-black;

      @include placeholder-styles {
        color: $color-black;
      }
    }
    .error-feedback {
      color: $color-red;
    }
  }
}
