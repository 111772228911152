@import '../base/variables';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.#{$opxp_edison_theme} .loading-indicator {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .spin {
    animation: spin 2s linear infinite;
  }
}
