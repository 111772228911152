@import './variables';

// additional color related variables and styles

// colors from figma
$color-black: #15364e;
$color-body-text: #617080;
$color-link-btn: #319dd9;
$color-white: #fff;
$color-light: #a7b8d1;
$color-red: #eb5757;
$color-green2: #27ae60;
// not in figma
$color-orange: #f9b69b;

// gradients
$linear-bg: linear-gradient(90deg, #1e84c6 0%, #23aadc 100%);
$linear-video: linear-gradient(135deg, #7aeed5 2.03%, #5594db 100%);
$linear-yellow: linear-gradient(90deg, #fce188 0%, #f8c050 100%);
$linear-purple: linear-gradient(135deg, #c4adf1 0%, #9275df 100%);
$linear-green: linear-gradient(90deg, #b1f2ed 0%, #79e0d6 100%);
