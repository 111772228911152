$opxp_edison_theme: 'theme-edison';
$assets-uri: 'https://cms.cdn.neuronation.com/assets/opxp';

// elements paddings
$padding-tiny: 8px;
$padding-small: 16px;
$padding-medium: 24px;
$padding-large: 32px;

// content paddings for sections
$padding-content-width: 15px;
$padding-content-height: 80px;
$padding-content-height-mobile: 40px;

$desktop-container-width: 1440px;
$desktop-section-width: 1110px;

// other styling
$icon-size-big: 64px;
$icon-size-small: 32px;

$video-shadow: 12px 24px 32px rgba(21, 54, 78, 0.2);

$card-shadow: 8px 16px 40px rgba(21, 54, 78, 0.16);
$card-border-radius: 24px;
