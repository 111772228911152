@import '../base/variables';
@import '../base/typography';
@import '../base/mobile';
@import './variables';

.#{$opxp_edison_theme} {
  .segment {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: $progress-size;
    height: $progress-size;
    .item {
      flex: 0 0 auto;
      position: relative;
      width: $progress-icon-size;
      height: $progress-icon-size;
      .item-icon {
        border-radius: 50%;
      }
      .item-value {
        @extend %button-medium;
        position: absolute;
        top: 0;
        left: 0;
        width: $progress-icon-size;
        height: $progress-icon-size;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $progress-color-green;
      }
      &.done {
        .item-value svg {
          width: calc($progress-icon-size / 2);
          height: calc($progress-icon-size / 2);
          color: $color-white;
        }
      }
    }
    .item-label {
      @extend %body-text;
      margin-top: $padding-tiny;
      flex: 1 1 100%;
      text-align: center;
      &.inactive {
        color: darken($progress-color-grey, 10%);
      }
      &.done {
        color: $progress-color-green;
      }
    }
    &.selectable:hover {
      cursor: pointer;
    }

    @include mq {
      flex-direction: row;
      max-width: inherit;
      height: 50px;
      .item {
        width: $progress-icon-size-mobile;
        height: $progress-icon-size-mobile;
        .item-icon,
        .item-value {
          width: $progress-icon-size-mobile;
          height: $progress-icon-size-mobile;
        }
      }
      .item-label {
        margin-top: 0;
        margin-left: $padding-medium;
        flex: 0 0 auto;
      }
    }
  }
}
