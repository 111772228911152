.carousel-slider {
  flex: 1 1 100px;
  display: flex;
  flex-direction: row;
  --num-slides: 3;
  --current-slide: 0;
  .nav-button {
    flex: 0 0 auto;
    width: 40px;
    display: flex;
    button {
      color: var(--color-link-btn);
      background-color: transparent;
      border: none;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
    svg {
      fill: var(--color-link-btn);
    }
  }
  .carousel-container {
    flex: 1 1 100%;
    overflow: hidden;
    height: 100%;
  }
  .carousel-content {
    position: relative;
    left: calc(-1 * 100% * var(--current-slide));
    top: 0;
    display: flex;
    flex-direction: row;
    width: calc(100% * var(--num-slides));
    height: 100%;
    transition: all 0.3s ease-out;
    .carousel-item {
      padding: 0 var(--padding-small);
      width: 100%;
    }
  }
}
