@import '../base/variables';
@import '../base/mobile';
@import './variables';

.#{$opxp_edison_theme} .section.banner-section {
  .banner-section-row {
    align-items: center;
  }

  .banner-section-banner {
    width: $section-asset-size;
    height: $section-asset-size;
    @include mq {
      width: $section-asset-size-mobile;
      height: $section-asset-size-mobile;
    }
  }

  &__left {
    .banner-section-row {
      flex-direction: row;
    }
  }
  &__right {
    .banner-section-row {
      flex-direction: row-reverse;
      @include mq {
        flex-direction: row;
      }
    }
  }

  @include mq {
    .banner-section-content {
      margin-top: $padding-medium;
    }
  }
}
