@import '../base/variables';
@import '../base/colors';
@import '../base/typography';
@import '../base/mobile';

.#{$opxp_edison_theme} .section {
  width: 100%;
  background-color: $color-white;
  color: $color-body-text;
  h1,
  h2,
  h3 {
    color: $color-black;
  }
  p {
    color: $color-body-text;
  }
  a {
    @extend %button-medium;
    color: $color-link-btn;
  }
  text-align: left;

  padding: 30px $padding-content-width;
  @include mq {
    padding: $padding-content-height-mobile $padding-content-width;
  }

  &.no-padding {
    padding: 0 $padding-content-width;
    @include mq {
      padding: 0 $padding-content-width;
    }
  }

  &__blue {
    background: $linear-bg;
    color: $color-white;
    h1,
    h2,
    h3,
    p,
    a {
      color: $color-white;
    }
  }

  &__orange {
    // this styles are not in figma!
    background: linear-gradient(to right, #ef9778, #f9b69b);
    color: $color-white;
    h1,
    h2,
    h3,
    p,
    a {
      color: $color-white;
    }
  }

  &__float-left {
    text-align: left;
  }

  &__float-center {
    text-align: center;
  }

  &__float-right {
    text-align: right;
  }
}
