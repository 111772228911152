@import '../base/variables';
@import '../base/colors';
@import '../base/typography';

.#{$opxp_edison_theme} .badge {
  @extend %small-text;
  width: 24px;
  height: 24px;
  text-align: center;
  border-radius: 12px;
  padding: 4px;
  &.green {
    color: $color-white;
    background-color: $color-green2;
  }
  &.red {
    color: $color-white;
    background-color: $color-red;
  }
  &.blue {
    color: $color-white;
    background-color: $color-link-btn;
  }
}
