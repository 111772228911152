@import '../base/variables';
@import '../base/mobile';
@import './variables';

.#{$opxp_edison_theme} {
  $border-height: 2px;
  $border-half: calc($border-height / 2);
  .circle-progress-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: $progress-container-height;
    .progress-bar {
      position: relative;
      display: flex;
      .progress-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: calc($progress-icon-size / 2);
        border-bottom: $border-height solid $progress-color-grey;
        margin: 0 calc($progress-size / 2);
      }
      .progress-items {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    @include mq {
      height: auto;
      .progress-bar {
        position: inherit;
        .progress-bg {
          position: inherit;
          height: inherit;
          flex: 0 0 auto;
          border-right: $border-height solid $progress-color-grey;
          border-bottom: none;
          margin: $progress-icon-size-mobile 0 $progress-icon-size-mobile $progress-icon-size-mobile;
        }
        .progress-items {
          position: inherit;
          flex-direction: column;
          margin-left: calc(#{-$progress-icon-size-mobile} / 2 - $border-half);
        }
      }
    }
  }
}
