@import './base/mobile';
@import './base/typography';
@import './base/colors';
@import './base/grid';

.#{$opxp_edison_theme} {
  // color css variables to use from js
  --color-black: #{$color-black};
  --color-body-text: #{$color-body-text};
  --color-link-btn: #{$color-link-btn};
  --color-white: #{$color-white};
  --color-light: #{$color-light};
  --color-red: #{$color-red};
  --color-green2: #{$color-green2};
  --color-light-blue-gray: #a7b8d1;
  --color-blue: #319dd9;
  --color-light-grey: #f8f8f8;
  --color-black-plus: #15364e;

  // weights
  --font-weight-thin: 200;
  --font-weight-light: 300;
  --font-weight-medium: 400;
  --font-weight-heavy: 500;

  // paddings
  --padding-tiny: #{$padding-tiny};
  --padding-small: #{$padding-small};
  --padding-medium: #{$padding-medium};
  --padding-large: #{$padding-large};

  // various useful variables
  --icon-size-big: #{$icon-size-big};
  --icon-size-small: #{$icon-size-small};
  --video-shadow: #{$video-shadow};
  --card-shadow: #{$card-shadow};
  --card-border-radius: #{$card-border-radius};

  // default typography for edison opxp theme
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-body-text;

  // root container
  .App {
    min-height: 100vh;
    width: 100%;
    max-width: $desktop-container-width;
    margin: auto;
    display: flex;
    flex-direction: column;
    @include mq {
      width: 100%;
    }
  }

  h1,
  .nn-h1 {
    @extend %h1;
    font-family: $font-family;
    margin: 0;
    margin-bottom: $padding-medium;
    color: $color-black;
    @include mq {
      margin-bottom: $padding-small;
    }
  }

  h2,
  .nn-h2 {
    @extend %h2;
    font-family: $font-family;
    margin: 0;
    margin-bottom: $padding-large;
    color: $color-black;
    @include mq {
      margin-bottom: $padding-medium;
    }
  }

  h3,
  .nn-h3 {
    @extend %h3;
    font-family: $font-family;
    color: $color-black;
    margin: 0;
  }

  a,
  button,
  label,
  li,
  p,
  span,
  .nn-body-text {
    @extend %body-text;
    font-family: $font-family;
  }

  p {
    margin: 0;
    margin-bottom: $padding-small;
  }

  a {
    color: $color-link-btn;
    text-decoration: none;
  }

  small,
  .nn-small {
    @extend %small-text;
  }

  .nn-button-medium {
    @extend %button-medium;
  }

  .nn-error {
    @extend %error;
  }
}
