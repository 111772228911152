@import '../base/variables';
@import '../base/mobile';
@import './variables';

.#{$opxp_edison_theme} {
  .circle-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: $progress-icon-size;
    height: $progress-icon-size;
    fill: $progress-color-white;
    .circle-border {
      stroke: $progress-color-grey;
    }
    .circle-value {
      stroke: $progress-color-green;
    }
    @include mq {
      width: $progress-icon-size-mobile;
      height: $progress-icon-size-mobile;
    }
  }

  .done {
    .circle-indicator .circle-border {
      fill: $progress-color-green;
      stroke: $progress-color-green;
    }
  }
}
