@import '../../base/common';

.cookie-consent-container {
  .cookie-consent-overlay {
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.7);
  }
  .cookie-consent-banner {
    z-index: 1001;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
    p {
      padding: var(--padding-tiny);
      margin-bottom: 0;
    }
    .row {
      align-items: center;
    }
    .cookie-consent-content {
      overflow-y: auto;
      // max height minus banner, header menu, section padding, title and subtitle
      max-height: calc(100vh - 563px);
    }
    .buttons-container {
      margin: auto 0;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: var(--padding-tiny) 0;
      @include mq {
        align-items: center;
        padding: var(--padding-tiny);
      }
      button.button {
        min-width: 120px;
        margin: var(--padding-tiny) 0;
        @include mq {
          max-width: 340px;
          width: 100%;
        }
      }
    }
  }
  .cookie-consent-modal-overlay {
    z-index: 1002;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.5);
  }
  .cookie-consent-modal {
    z-index: 1003;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    width: 740px;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    background: #fff;
    padding: var(--padding-small);
    border: 1px solid rgba(0, 0, 0, 0.8);
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      h2 {
        margin: 0;
      }
      .close-button {
        font-size: 32px;
        line-height: 32px;
        font-weight: 300;
        width: 32px;
        cursor: pointer;
        text-align: center;
      }
      margin-bottom: var(--padding-small);
    }
    .modal-form {
      margin: var(--padding-small) 0;
      .consent-checkbox {
        margin-bottom: var(--padding-small);
      }
    }
    .buttons-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        margin: 0;
        margin-left: var(--padding-small);
      }
      @include mq {
        flex-direction: column;
        align-items: flex-start;
        button {
          margin: 0;
          margin-bottom: var(--padding-small);
        }
      }
    }
  }
}
