@import '../base/variables';
.#{$opxp_edison_theme} .nav-2 {
  .nav-item-2 {
    padding: 0 10px;
    a,
    button {
      text-decoration: none;
      display: flex;
      align-items: center;
      border: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    &:first-child {
      padding-left: 0;
    }
  }
  &.vertical {
    .nav-item-2 {
      padding: 10px 0;
      &:last-child {
        padding-bottom: 0;
      }
      &:first-child {
        padding-top: 0;
      }
    }
  }
}
