.theme-edison .banner-container {
  overflow: hidden;
  transition: 0.3s ease-in-out;
  --banner-back-color: #ed9a87;
  --banner-front-color: var(--color-black);
  .banner {
    position: relative;
    transition: 0.3s ease-in-out;
    display: flex;
    align-items: flex-start;
    &.green {
      --banner-back-color: var(--color-green2);
      --banner-front-color: var(--color-white);
    }
    &.blue {
      --banner-back-color: #e9f8ff;
      --banner-front-color: #537597;
    }
    background-color: var(--banner-back-color);
    .banner-main {
      display: flex;
      flex: 1 1 100%;
      justify-content: center;
      & > h3,
      p,
      span,
      a,
      .banner-content {
        color: var(--banner-front-color);
        font-size: 32px;
        line-height: 56px;
        font-weight: 400;
        margin-top: 6px;
        margin-bottom: 2px;
        text-align: center;
      }
    }
    .banner-close {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      color: var(--banner-front-color);
      font-size: 32px;
      line-height: 32px;
      font-weight: 300;
      margin-top: 4px;
      margin-right: 4px;
      margin-left: 32px;
    }
  }
}
