@import '../base/variables';
@import '../base/colors';
@import '../base/typography';

.#{$opxp_edison_theme} {
  .speedometer-card {
    margin-bottom: var(--padding-medium);
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    &-icon-wrapper {
      display: flex;
      justify-content: center;
      border-radius: 50%;
      z-index: 1;
    }
    &-icon {
      height: var(--icon-size-big);
      width: var(--icon-size-big);
      border-radius: 50%;
    }
    &-wrapper {
      min-height: 100px;
      padding-top: calc(var(--icon-size-big) / 2);
      margin-top: calc(-1 * var(--icon-size-big) / 2);
      border-radius: var(--card-border-radius);
      background-color: var(--color-white);
      box-shadow: var(--card-shadow);
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-title {
      @extend %button-medium;
      color: var(--color-black);
      margin-top: var(--padding-medium);
      margin-bottom: var(--padding-small);
    }
    &-subtitle {
      min-height: var(--padding-medium);
      margin-top: 10px;
    }
  }
}
