@import '../base/variables';
@import '../base/colors';
@import '../base/typography';

.#{$opxp_edison_theme} {
  .gauge {
    max-width: 220px;
    padding: 0 50px;
    margin: auto;
    .gauge-speedometer {
      width: 100%;
      margin-bottom: var(--padding-tiny);
    }
    &-labels {
      display: flex;
      justify-content: space-between;
    }
    &-label {
      @extend %small-text;
      color: var(--color-light);
    }
  }
}
