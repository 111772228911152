@import '../base/variables';

.#{$opxp_edison_theme} .nav-2 {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  &.vertical {
    flex-direction: column;
    align-items: start;
  }
}
