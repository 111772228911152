@import '../base/variables';
@import '../base/colors';
@import '../base/typography';
@import '../base/mobile';

.#{$opxp_edison_theme} .header-2 {
  background-color: $color-white;
  color: $color-body-text;
  padding: 21px $padding-content-width;
  //future feature: see BW-6093
  &.slim {
    padding: 10px $padding-content-width;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .header-common,
  .header-collapsible {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: space-between;
  }

  .header-common {
    flex: 1 0 auto;
  }

  .nav-toggle {
    display: none;
    border: none;
    width: 24px;
    height: 24px;
    padding: 0;
  }

  .logo-link {
    text-decoration: none;
    padding: 4px 0;
    line-height: 0;
    .header-logo {
      height: 30px;
    }
  }

  .header-nav {
    position: relative;
    .nav-menu-2 .nav-menu-container {
      .nav-2.vertical .nav-item-2 {
        a,
        button,
        span:not(.badge) {
          padding: 8px 12px;
        }
        &.has-badge {
          padding-right: 8px;
          .avatar-container {
            margin-right: 12px;
          }
          a,
          button,
          span {
            max-width: 160px;
          }
          &:hover {
            background-color: var(--color-link-btn);
            color: var(--color-white);
          }
        }
      }
    }
  }

  .header-nav .nav-item,
  .header-nav .nav-item-2 {
    display: flex;
    padding: 0;
    margin: 0;
    margin-left: $padding-medium;
    &:first-child {
      margin-left: 0;
    }
    a,
    button {
      display: flex;
      @extend %body-text;
      color: $color-body-text;
      text-decoration: none;
      background-color: transparent;
      padding: 4px 12px;
      cursor: pointer;
      &:hover {
        text-decoration: none;
        background-color: $color-link-btn;
        color: $color-white;
        cursor: pointer;
        & > a,
        & > button {
          color: var(--color-white);
        }
        & + .nav-item-icon svg {
          fill: var(--color-white);
        }
        & + .nav-item-badge {
          background-color: var(--color-white);
          color: var(--color-link-btn);
        }
      }
      span:not(.avatar-container) {
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .avatar-container {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 4px;
      .user-avatar.small {
        width: 24px;
        height: 24px;
      }
      .menu-badge {
        position: absolute;
        top: -12px;
        right: -12px;
        .badge {
          display: block;
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  &.open {
    .header-nav .nav-item,
    .header-nav .nav-item-2 {
      a,
      button,
      .user-menu-label {
        padding: 8px 12px;
      }
    }
  }

  // mobile style
  @include mq {
    padding: 24px $padding-content-width;
    .header-common,
    .header-collapsible {
      flex: 1 0 100%;
    }
    .nav-toggle {
      display: flex;
      outline: none;
      background-color: $color-white;
    }
    .logo-link .header-logo {
      height: 24px;
    }
    &.closed {
      .header-collapsible {
        display: none;
      }
    }
    &.open {
      .header-collapsible {
        display: flex;
        margin-top: 24px;
      }
      .header-nav {
        width: 100%;
        justify-content: space-between;
      }
      .header-nav .nav-item,
      .header-nav .nav-item-2 {
        margin: 0;
        button,
        a {
          padding: 0;
        }
      }
    }
  }
}
