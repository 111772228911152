@import './variables';
@import './colors';
@import './mobile';

$font-family: 'Roboto', sans-serif;

$font-weight-light: 300;
$font-weight-300: $font-weight-light;
$font-weight-normal: 400;
$font-weight-400: $font-weight-normal;
$font-weight-medium: 500;
$font-weight-500: $font-weight-medium;
$font-weight-bold: 700;
$font-weight-700: $font-weight-bold;

%h1 {
  font-weight: $font-weight-medium;
  font-size: 48px;
  line-height: 56px;
  @include mq {
    font-size: 36px;
    line-height: 42px;
  }
}

%h2 {
  font-weight: $font-weight-light;
  font-size: 24px;
  line-height: 32px;
  @include mq {
    font-size: 20px;
    line-height: 32px;
  }
}

%h3 {
  font-weight: $font-weight-bold;
  font-size: 24px;
  line-height: 32px;
}

%button-medium {
  font-weight: $font-weight-medium;
  font-size: 18px;
  line-height: 22px;
}

%body-text {
  font-weight: $font-weight-normal;
  font-size: 18px;
  line-height: 30px;
  @include mq {
    font-size: 16px;
    line-height: 26px;
  }
}

%small-text {
  font-weight: $font-weight-normal;
  font-size: 14px;
  line-height: 16px;
}

%error {
  font-weight: $font-weight-medium;
  font-size: 12px;
  line-height: 16px;
}
