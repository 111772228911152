@import '../base/variables';

.#{$opxp_edison_theme} .nav-2 .nav-menu-2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 300ms ease;
  text-align: left;
  margin: 0;
  .nav-menu-popover {
    display: flex;
    flex: 0 0 auto;
  }
  .nav-menu-container {
    background-color: var(--color-white);
    width: 180px;
    padding: var(--padding-tiny) 0;
    .nav-item-2 {
      width: 100%;
      flex: 1 1 100%;
      display: flex;
      margin: 0;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      a,
      button,
      span:not(.badge) {
        flex: 1 1 100%;
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
