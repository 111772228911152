@import '../base/variables';
@import '../base/colors';
@import '../base/typography';

.#{$opxp_edison_theme} {
  // global navigation container styles
  .nav {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    .nav-item {
      padding: 0 10px;
      a,
      button {
        text-decoration: none;
        display: flex;
        align-items: center;
        border: 0;
        color: $color-black;
      }
      &:last-child {
        padding-right: 0;
      }
      &:first-child {
        padding-left: 0;
      }
    }
    &__vertical {
      flex-direction: column;
      align-items: start;
      .nav-item {
        padding: 10px 0;
        &:last-child {
          padding-bottom: 0;
        }
        &:first-child {
          padding-top: 0;
        }
      }
    }
  }
}
