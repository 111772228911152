@import '../base/variables';
@import '../base/colors';
@import '../base/typography';
@import '../base/mobile';

.language-selector {
  @extend %body-text;
  cursor: pointer;
  &-item {
    @extend %body-text;
    color: $color-black;
    display: flex;
    padding: 8px 4px;
    min-width: 120px;
    &:hover {
      background-color: $color-link-btn;
      color: $color-white;
      cursor: pointer;
    }
    @include mq {
      // do not change to mobile font for this use case
      font-weight: $font-weight-normal;
      font-size: 18px;
      line-height: 30px;
    }
  }
}
