@import '../base/variables';
@import '../base/colors';

.#{$opxp_edison_theme} .user-avatar {
  display: inline-block;
  width: 160px;
  height: 160px;
  border: 1px solid $color-light;
  background-color: $color-white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  // svg background with png fallback
  // sass-lint:disable-block no-duplicate-properties
  background-image: url('#{$assets-uri}/feature/profile/avatar_big.png');
  background-image: url('#{$assets-uri}/feature/profile/avatar_big.svg'), linear-gradient(transparent, transparent);
  &.big {
    width: 230px;
    height: 230px;
  }
  &.small {
    width: 24px;
    height: 24px;
    // sass-lint:disable-block no-duplicate-properties
    background-image: url('#{$assets-uri}/feature/profile/avatar_small.png');
    background-image: url('#{$assets-uri}/feature/profile/avatar_small.svg'), none;
    border: 0;
  }
}
