.theme-edison .passkey-confirmation-dialog {
  padding: 20px;
  margin: 0;
  p {
    margin: 0 13px 0 13px;
  }
  ul {
    margin: 16px 13px 0 13px;
    list-style: none;
    padding-left: 0;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      img {
        margin-right: 6px;
      }
    }
  }
  .action-buttons {
    display: flex;
    flex-direction: column;
    & button {
      margin-top: 16px;
    }
  }
}
