@import './variables';

// screen size definitions
$mobile-width: $desktop-section-width + 20px;
$mobile-height: 640px;

// media breakpoints definition
// mobile screen width is less than $mobile-width
$bp-mobile-width: '(max-width: #{$mobile-width})';
// or height is less than $mobile-height
$bp-mobile-height: '(max-height: #{$mobile-height})';

$bp-mobile-screen: '#{$bp-mobile-width}, all and #{$bp-mobile-height}';

@mixin mq {
  @media #{$bp-mobile-screen} {
    @content;
  }
}
