.theme-edison {
  .app-toaster {
    position: fixed !important;
    h3 {
      line-height: 21px;
      font-size: 16.38px;
      margin-block-end: 16.38px;
      margin-block-start: 16.38px;
    }
    p,
    span {
      font-size: 14px;
      line-height: 18px;
      margin-block-end: 10px;
      margin-bottom: 10px;
      word-break: break-word;
    }
    /* we need to apply white color only for notifications and exclude push message which does not have an intent */
    [class*='intent'] {
      color: var(--color-white);
      h3,
      p,
      span {
        color: var(--color-white);
      }
    }
  }
}
