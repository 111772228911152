@import '../base/variables';
@import '../base/colors';
@import '../base/mobile';

.#{$opxp_edison_theme} .trust-banner {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  .left,
  .right {
    flex: 1 1 auto;
  }
  .clickable {
    cursor: pointer;
  }
  p {
    text-transform: uppercase;
    color: $color-light;
    font-size: 12px;
    line-height: 16px;
    padding: 2px;
    margin-bottom: 0;
  }
  .trust-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    width: 100%;
    img {
      margin-top: $padding-small;
      min-height: 50px;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
  .gap {
    flex: 1 1 0;
  }
  @include mq {
    .gap {
      display: none;
    }
    p {
      margin-top: 24px;
    }
    .trust-items {
      img {
        max-height: 40px;
        min-height: unset;
        &:not(:last-child) {
          margin-right: 0;
        }
      }
    }
  }
}

.trust-banner-dialog {
  width: auto;
  max-width: 768px;
  &-body {
    overflow-y: scroll;
    max-height: 636px;
    margin-bottom: 0;
    img {
      width: 100%;
    }
  }
}
