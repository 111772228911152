@import '../base/variables';
@import '../base/colors';
@import '../base/typography';

.#{$opxp_edison_theme} {
  .nn-button {
    height: 56px;
    padding: 0 20px;
    border: none;
    border-radius: 25px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
    font-family: $font-family;
    font-size: 20px !important;
    font-weight: $font-weight-medium !important;
    line-height: 56px !important;
    min-width: 250px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: uppercase;
    text-align: center;
    background-color: $color-white;
    color: $color-black;
    transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);
    cursor: pointer;
    &:disabled,
    &:hover,
    &:active {
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    }
    &:disabled {
      cursor: not-allowed;
    }

    &.nn-button__white {
      background-color: $color-white;
      color: $color-black;
      &:disabled {
        background-color: darken($color-white, 10%);
      }
    }

    &.nn-button__blue {
      background-color: $color-link-btn;
      color: $color-white;
      &:hover,
      &:active {
        color: $color-white;
      }
      &:disabled {
        background-color: darken($color-link-btn, 10%);
      }
    }

    &.nn-button__green {
      background-color: $color-green2;
      color: $color-white;
      &:hover,
      &:active {
        color: $color-white;
      }
      &:disabled {
        background-color: darken($color-green2, 10%);
      }
    }

    &.nn-button__red {
      background-color: $color-white;
      color: $color-red;
      &:hover,
      &:active {
        color: $color-red;
      }
      &:disabled {
        background-color: darken($color-white, 10%);
      }
    }
  }

  // special fixes for links looking like buttons
  a.nn-button {
    display: inline-block;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}
