@import '../base/variables';
@import '../base/colors';

.#{$opxp_edison_theme} .footer-icons-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .nav-item {
    a {
      color: $color-black;
    }
  }
}
