@import './variables';
@import './mobile';

// default grid for edison opxp theme
.#{$opxp_edison_theme} {
  $desktop-columns: 12; // Number of columns in the desktop grid system
  $mobile-columns: 4; // Number of columns in the mobile grid system
  $grid-gutter-width: 30px;
  $column-gutter: calc($grid-gutter-width / 2);

  .container {
    width: 100%;
    // section internal width
    max-width: $desktop-section-width;
    margin: auto;
    @include mq {
      max-width: 100%;
    }
  }

  .row {
    min-height: 1px;
    display: flex;
    flex-wrap: wrap;
    margin-right: -1 * $column-gutter;
    margin-left: -1 * $column-gutter;

    & > * {
      box-sizing: border-box;
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      min-height: 1px;
      padding-right: $column-gutter;
      padding-left: $column-gutter;
    }

    // render grid without gutters
    &.no-gutters {
      margin-right: 0;
      margin-left: 0;
      > .col,
      > [class*='col-'] {
        padding-right: 0;
        padding-left: 0;
      }
    }

    .col {
      flex: 1 0 0%;
      min-width: 0;
    }

    // generate desktop columns
    @for $width from 1 through $desktop-columns {
      .col-#{$width} {
        flex: 0 0 auto;
        width: calc($width / $desktop-columns * 100%);
      }
    }

    // generate mobile columns
    @include mq {
      @for $width from 1 through $mobile-columns {
        .col-m-#{$width} {
          flex: 0 0 auto;
          width: calc($width / $mobile-columns * 100%);
        }
      }

      .col-m-0 {
        display: none;
      }
    }
  }
}
