@import '../base/variables';
@import '../base/colors';
@import '../base/typography';

.nn-dialog-component {
  width: 75%;
  padding: 0;
  position: relative;

  .dialog-header {
    padding: $padding-small;
  }

  .dialog-body {
    padding: $padding-large;
  }

  .close-button {
    color: $color-link-btn;
    position: absolute;
    border-radius: 50%;
    border: solid 1px $color-light;
    top: $padding-tiny;
    right: $padding-tiny;
    padding: 0 $padding-tiny;
    cursor: pointer;
  }

  .dialog-footer {
    padding: $padding-small;
  }
}
