@import '../base/variables';
@import '../base/colors';
@import '../base/typography';
@import '../base/mobile';

.#{$opxp_edison_theme} {
  .header {
    background-color: $color-white;
    color: $color-body-text;
    padding: 21px $padding-content-width;

    .container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .header-common,
    .header-collapsible {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      justify-content: space-between;
    }

    .header-common {
      flex: 1 0 auto;
    }

    .nav-toggle {
      display: none;
      border: none;
      width: 24px;
      height: 24px;
      padding: 0;
    }

    .logo-link {
      text-decoration: none;
      padding: 4px 0;
      line-height: 0;
      .header-logo {
        height: 30px;
      }
    }

    .header-nav {
      position: relative;
    }

    .header-nav .nav-item {
      padding: 0;
      margin-left: $padding-medium;
      &:first-child {
        margin-left: 0;
      }
      a,
      button {
        @extend %body-text;
        color: $color-body-text;
        background-color: transparent;
        padding: 4px 12px;
        cursor: pointer;
        &:hover {
          text-decoration: none;
          background-color: $color-link-btn;
          color: $color-white;
        }
      }
    }

    // mobile style
    @include mq {
      padding: 24px $padding-content-width;
      .header-common,
      .header-collapsible {
        flex: 1 0 100%;
      }
      .nav-toggle {
        display: flex;
        outline: none;
        background-color: $color-white;
      }
      .logo-link .header-logo {
        height: 24px;
      }
      &.closed {
        .header-collapsible {
          display: none;
        }
      }
      &.open {
        .header-collapsible {
          display: flex;
          margin-top: 24px;
        }
        .header-nav {
          width: 100%;
          justify-content: space-between;
        }
        .header-nav .nav-item {
          margin: 0;
          button,
          a {
            padding: 0;
          }
        }
      }
    }
  }
}
